import store from "@/store";
import router from "@/router";

export default {
  inserted: function (el, binding) {
    const menu = router.currentRoute.meta.title
      ? router.currentRoute.meta.title
      : "";
    const menuAuth = store.state.userAuth.find((item) => item.title === menu);

    if (!menuAuth) {
      binding.value.auth.noAuth = true;
    } else {
      const btnAuth = menuAuth.children.find(
        (item) => item.title == binding.value.auth.name
      );

      if (!btnAuth) {
        binding.value.auth.noAuth = true;
      }
    }

    el.addEventListener("click", () => {
      // 无按钮权限
      if (binding.value.auth.noAuth) {
        ELEMENT.MessageBox.alert("您没有权限操作", "提示", {
          confirmButtonText: "确定",
          lockScroll: false,
        });
       
        return;
      }
      binding.value.handler(binding.value.auth);
    });
  },
};

<template>
  <div class="advanced-search custom-box-shadow" :style="warpStyle">
    <el-button v-if="!isAdvancedSearchShow" @click="open" type="primary"
      >高级检索</el-button
    >
    <div class="advanced-search-content" v-if="isAdvancedSearchShow">
      <!-- <el-form ref="ruleForm" label-width="0.8rem" class="demo-ruleForm">
        <el-row>
          <template v-for="(item, index) in searchList">
            <el-col :span="12" :key="index">
              <el-form-item label="行业分类">
                <el-select
                  v-model="item.searchValue"
                  placeholder="请选择"
                  @change="(val) => changeSelect(val, item, index)"
                  @visible-change="(val) => visibleChange(val, item)"
                >
                  <el-option
                    v-for="el in options"
                    :key="el.value"
                    :label="el.label"
                    :value="el.value"
                    :disabled="el.disabled"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-model="item.inputValue"
                  placeholder="请输入"
                ></el-input>
                <img
                  class="del-icon"
                  v-if="index != 0"
                  src="@/assets/icon_del.png"
                  alt=""
                  @click="delSearch(index)"
                />
              </el-form-item>
            </el-col>
          </template>
        </el-row>

        <el-form-item>
          <el-button class="addSearchBtn" icon="el-icon-plus" @click="add"
            >添加筛选条件</el-button
          >
        </el-form-item>
      </el-form> -->
      <el-form ref="ruleForm" label-width="0.1rem" class="demo-ruleForm">
        <template v-for="(item, index) in searchList">
          <el-col :span="12" :key="index">
            <el-form-item>
              <el-select
                v-model="item.searchValue"
                placeholder="请选择"
                @change="(val) => changeSelect(val, item, index)"
                @visible-change="(val) => visibleChange(val, item)"
              >
                <el-option
                  v-for="el in options"
                  :key="el.value"
                  :label="el.label"
                  :value="el.value"
                  :disabled="el.disabled"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="item.inputValue"
                placeholder="请输入"
              ></el-input>
              <img
                class="del-icon"
                v-if="index != 0"
                src="@/assets/icon_del.png"
                alt=""
                @click="delSearch(index)"
              />
            </el-form-item>
          </el-col>
        </template>
        <el-col :span="24">
          <el-form-item>
            <el-button class="addSearchBtn" icon="el-icon-plus" @click="add"
              >添加筛选条件</el-button
            >
          </el-form-item>
        </el-col>
      </el-form>
      <div class="search-form-btn">
        <el-button @click="resetForm('ruleForm')">取消</el-button>

        <el-button type="primary" @click="submitForm('ruleForm')"
          >检索</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getIndustry, getAchieve_search_type, getSearch_type } from "@/api/air";
export default {
  props: {
    searchParams: {
      type: Object,
      default() {
        return {};
      },
    },
    isAdvancedSearchShow: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      options: [],
      checked: [],
      input: "",
      value: "",
      searchList: [
        {
          searchValue: "",
          inputValue: "",
        },
      ],
      warpStyle: {
        width: "1.2rem",
        background: "transparent",
        padding: "0",
        marginLeft: "0.2rem",
      },
    };
  },
  watch: {
    isAdvancedSearchShow: {
      handler() {
        this.changeView();
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getSearchType();
      this.changeView();
    },
    changeView() {
      if (this.isAdvancedSearchShow) {
        this.warpStyle.width = "100%";
        this.warpStyle.background = "#fff";
        this.warpStyle.padding = "0.2rem";
        this.warpStyle.marginLeft = "0";
      } else {
        this.warpStyle.width = "1.2rem";
        this.warpStyle.background = "transparent";
        this.warpStyle.padding = "0";
        this.warpStyle.marginLeft = "0.2rem";
      }
    },
    getSearchType() {
      if (this.searchParams.nameType == "1") {
        getIndustry().then((res) => {
          let arr = res.data;
          arr.forEach((val) => {
            val.value = val.id;
            val.label = val.name;
            val.disabled = false;
          });
          this.options = [...this.options, ...arr];
        });
      } else if (this.searchParams.nameType == "2") {
        getAchieve_search_type().then((res) => {
          let arr = res.data;
          arr.forEach((val) => {
            val.value = val.id;
            val.label = val.name;
            val.disabled = false;
          });
          this.options = [...this.options, ...arr];
        });
      } else {
        getSearch_type().then((res) => {
          let arr = res.data;
          arr.forEach((val) => {
            val.value = val.id;
            val.label = val.name;
            val.disabled = false;
          });
          this.options = [...this.options, ...arr];
        });
      }
    },
    open() {
      this.$emit("openAdvancedSearch");
    },
    submitForm() {
      const searchIds = Array.from(
        this.searchList,
        ({ searchValue }) => searchValue
      );
      const inputValues = Array.from(
        this.searchList,
        ({ inputValue }) => inputValue
      );
      this.searchParams.search_type = searchIds.join(",");
      this.searchParams.keyword = inputValues.join(",");
      this.searchParams.type = 2;
      this.$emit("submitForm", this.searchParams);
      // this.$emit("closeAdvancedSearch");
    },
    resetForm() {
      this.searchList = [
        {
          searchValue: "",
          inputValue: "",
        },
      ];
      this.checked = [];
      this.$emit("submitForm");
      this.$emit("closeAdvancedSearch");
    },
    changeSelect(val, data, index) {
      let flag = false;
      let arr = [...this.checked];
      if (!this.checked[index]) {
        data.index = index;
        arr.push(data);
        this.checked = [...arr];
      } else {
        this.checked[index].searchValue = val;
      }
    },
    visibleChange(val, data) {
      const checked = Array.from(
        this.checked,
        ({ searchValue }) => searchValue
      );
      if (val) {
        this.options.forEach((el) => {
          if (checked.includes(el.value) && el.value != data.searchValue) {
            el.disabled = true;
          } else {
            el.disabled = false;
          }
        });
      }
    },
    add() {
      this.searchList.push({
        searchValue: "",
        inputValue: "",
      });
    },
    delSearch(index) {
      this.searchList.splice(index, 1);
      this.checked.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.advanced-search {
  // width: 100%;
  // background-color: #fff;
  padding: 0.2rem;

  .el-button {
    padding: 0;
  }

  .advanced-search-content {
    .el-form-item {
      .el-form-item__content {
        @include flex(row, flex-start, center);

        .el-select,
        .el-input {
          width: 2.2rem;
          margin-right: 0.2rem;

          .el-input__inner {
            height: 0.32rem;
            line-height: 0.32rem;
            background: #f7f8fa;
            border: 0;
          }
        }

        .del-icon {
          width: 0.18rem;
          height: 0.18rem;
          padding: 0.1rem;
          cursor: pointer;
        }

        .addSearchBtn {
          width: 2.2rem;
          height: 0.38rem;
          color: var(--clkd-color-global);
          border: 1px solid var(--clkd-color-global);
        }
      }
    }

    .search-form-btn {
      float: right;

      .el-button {
        width: 1rem;
        height: 0.38rem;
      }
    }
  }
}
</style>

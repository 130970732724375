<template>
  <el-pagination
    class="global-pagination-warp"
    @size-change="paginationConfig.handleSizeChange"
    @current-change="paginationConfig.handleCurrentChange"
    :current-page="paginationConfig.currentPage"
    :page-sizes="paginationConfig.pageSizes"
    :page-size="paginationConfig.pageSize"
    :layout="paginationConfig.layout"
    :total="paginationConfig.total"
  >
  </el-pagination>
</template>

<script>
export default {
  props: {
    paginationConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.global-pagination-warp {
  margin: 0.1rem 0;
  text-align: right;
  padding: 0;
  .el-pagination__total {
    float: left;
    height: 0.32rem !important;
  }
  .el-pager {
    .number {
      width: 0.32rem;
      height: 0.32rem;
      line-height: 0.32rem;
      font-size: 0.14rem;
      border-radius: 0.03rem;
      background: #ffffff;
      border: 1px solid #dcdcdc;
      color: rgba(0, 0, 0, 0.9);
      margin-left: 0.05rem;
      font-family: PingFangSC-Regular;
    }
    .active {
      color: rgba(255, 255, 255, 0.9);
      background: var(--clkd-color-global);
    }
  }
  .btn-next,
  .btn-prev {
    height: 0.32rem;
    background: transparent;
  }
  .el-pagination__sizes,
  .el-select--mini,
  .el-input__inner {
    height: 0.32rem !important;
  }
  .el-pagination__jump {
    background: #f3f3f3;
    height: 0.32rem !important;
    line-height: 0.32rem !important;
    padding: 0 0.08rem;
    .el-pagination__editor {
      width: 0.57rem;
      height: 0.28rem;
      line-height: 0.32rem;
    }
  }
}
</style>

<template>
  <div v-if="!isAdvancedSearchShow" class="fuzzy-search custom-box-shadow">
    <el-input
      id="searchInput"
      placeholder="请输入两个字及以上关键字"
      prefix-icon="el-icon-search"
      v-model="value"
      @change="onChange"
      clearable
      @keyup.enter.native="enter"
    >
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    isAdvancedSearchShow: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    onChange(value) {
      if (value) {
      }
      this.$emit("searchData", value);
    },
    enter(e) {
      // if (e) {
      // }
      this.$emit("searchData", this.value);
    },
  },
};
</script>

<style></style>

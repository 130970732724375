import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {  // 用于存放数据
    themeClass: localStorage.getItem("themeClass"),
    userInfo: sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : null,
    userAuth: sessionStorage.getItem("userAuth")
      ? JSON.parse(sessionStorage.getItem("userAuth"))
      : [],

    userDBAuth: sessionStorage.getItem("userDBAuth")
      ? JSON.parse(sessionStorage.getItem("userDBAuth"))
      : [],
  },
  getters: {  // 用于获取数据，state的计算属性  
  },
  mutations: {  // 更改state中状态的逻辑，同步操作
    // 设置主题
    setTheme(state, payload) {
      localStorage.setItem("themeClass", payload);
      state.themeClass = payload;
    },
    // 切换语言
    changeLanguage(state, payload) {
      localStorage.setItem("lanague", payload); // 这样页面刷新就不会改变选择的语言了
      this.$i18n.locale = payload;
    },
    // 设置用户信息
    setUserInfo(state, payload) {
      sessionStorage.setItem("userInfo", JSON.stringify(payload));
      state.userInfo = payload;
    },
    // 设置用户官网权限
    setUserAuth(state, payload) {
      sessionStorage.setItem("userAuth", JSON.stringify(payload));
      state.userAuth = payload;
    },
    //设置用户数据库权限
    setUserDBAuth(state, payload) {
      sessionStorage.setItem("userDBAuth", JSON.stringify(payload));
      state.userDBAuth = payload;
    }
  },
  actions: {
     // 提交mutation，异步操作
  },
  modules: {},
  plugins: [createPersistedState()],
}
);

import { get, post } from "../services/http";

// 首页---数据概览 /api/website/statistics
export function getStatisticsData(data = {}) {
  return post(`/api/website/statistics`, data);
}

// 首页---需求列表 /api/website/getIndexDemandList
export function getIndexDemandList(data = {}) {
  return post(`/api/website/getIndexDemandList`, data);
}

// 首页---成果列表 /api/website/getIndexAchievementList
export function getIndexAchievementList(data = {}) {
  return post(`/api/website/getIndexAchievementList`, data);
}

// 首页---成功案例 /api/website/getSuccessList
export function getSuccessList(data = {}) {
  return post(`/api/website/getSuccessList`, data);
}

// 首页---专家照片墙 /api/website/getIndexSpecialist
export function getIndexSpecialist(data = {}) {
  return post(`/api/website/getIndexSpecialist`, data);
}

// 技术需求---需求状态 /api/website/getStatusDemand
export function getStatusDemand(data = {}) {
  return post(`/api/website/getStatusDemand`, data);
}

// 技术需求---列表 /api/website/getDemandList
export function getDemandList(data = {}) {
  return post(`/api/website/getDemandList`, data);
}

// 技术需求---详情页 /api/website/GetDemandDetail
export function getDemandDetail(data = {}) {
  return post(`/api/website/GetDemandDetail`, data);
}

// 科技成果---成果状态 /api/website/getStatusAchievement
export function getStatusAchievement(data = {}) {
  return post(`/api/website/getStatusAchievement`, data);
}

// 科技成果---列表 /api/website/getAchievementList
export function getAchievementList(data = {}) {
  return post(`/api/website/getAchievementList`, data);
}

// 科技成果---详情页 /api/website/getAchieveDetail
export function getAchieveDetail(data = {}) {
  return post(`/api/website/getAchieveDetail`, data);
}

// 科技成果---已联系数量 /api/website/collect_num
export function getCollectNum(data = {}) {
  return post(`/api/website/collect_num`, data);
}

// 个人信息列表 /api/person/user_list
export function getUserList(data = {}) {
  return post(`/api/person/user_list`, data);
}

// 技术经理人---列表 /api/website/getIndexManagerList
export function getIndexManagerList(data = {}) {
  return post(`/api/website/getIndexManagerList`, data);
}

// 产业资源库---列表 /api/website/getCorporationList
export function getCorporationList(data = {}) {
  return post(`/api/website/getCorporationList`, data);
}

//行业领域 
export function getInsudtrylist(data) {
  return post(`/api/dropdown/insudtrylist`, data)
}

// // 高级搜索下拉 /api/common/get_industry
// export function getIndustry(params = {}) {
//   return get(`/api/common/get_industry`, params);
// }
// 需求-高级搜索下拉 /api/dropdown/Demand_search_type
export function getIndustry(params = {}) {
  return post(`/api/dropdown/Demand_search_type`, params);
}
// 成果-高级搜索下拉 /api/dropdown/Achieve_search_type
export function getAchieve_search_type(params = {}) {
  return post(`/api/dropdown/Achieve_search_type`, params);
}
// 专家-高级搜索下拉 /api/dropdown/search_type
export function getSearch_type(params = {}) {
  return post(`/api/dropdown/search_type`, params);
}


//高级搜索下拉
export function getDemandSearchOptions() {
  return post(`/api/dropdown/Demand_search_type`)
}

//合作方式
export function getWayList() {
  return post(`/api/dropdown/waylist`)
}

//技术经理人照片
export function getManagerList() {
  return post(`/api/website/getManagerList`)
}

//专家照片墙
export function getExpertList() {
  return post(`/api/website/getIndexSpecialist`)
}

// 官网----需求，成果，专家统计 /api/website/getCountNum
export function getCountNum(data = {}) {
  return post(`/api/website/getCountNum`, data);
}

// 官网----系统管理员待办事项 /api/website/getSystemBackLog
export function getSystemBackLog(data = {}) {
  return post(`/api/website/getSystemBackLog`, data);
}

// 官网----技术经理人待办事项 /api/website/getBackLog
export function getBackLog(data = {}) {
  return post(`/api/website/getBackLog`, data);
}

// 技术需求---投递方案 /api/website/setDeliverAdd
export function setDeliverAdd(data = {}) {
  return post(`/api/website/setDeliverAdd`, data);
}

// 轮播图 /api/team/banner
export function getBanner(data = {}) {
  return post(`/api/website/banner`, data);
}

// 个人信息详情页 /api/website/person_details
export function getPersonDetail(data = {}) {
  return post(`/api/website/person_details`, data);
}

// 提交技术经理人 /api/website/setSubmitManager
export function setSubmitManager(data = {}) {
  return post(`/api/website/setSubmitManager`, data);
}

// 成为技术经理人回显 /api/website/EchoManager
export function EchoManager(data = {}) {
  return post(`/api/website/EchoManager`, data);
}

// 个人信息详情页  /api/website/addLookRelate
export function addLookRelate(data = {}) {
  return post(`/api/website/addLookRelate`, data);
}

// (联系成果方) /api/website/getLinkGain
export function getLinkGain(data = {}) {
  return post(`/api/website/getLinkGain`, data);
}

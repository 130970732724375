export default function toFormData(form) {
  const params = new FormData();

  for (const key in form) {
    if (Object.hasOwnProperty.call(form, key)) {
      params.append(key, form[key]);
    }
  }

  return params;
}

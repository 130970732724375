import Login from "@/views/Login/Index";
import MainLayout from "@/views/MainLayout/Index";

export default [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/main-layout",
    component: MainLayout,
    children: [
      {
        path: "home",
        name: "Home",
        meta: {
          title: "首页",
          icon: "",
          keepAlive: true,
        },
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/Home/Index"),
      },
      {
        path: "technical-needs",
        name: "TechnicalNeeds",
        meta: {
          title: "技术需求",
          icon: "",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "TechnicalNeeds" */ "@/views/TechnicalNeeds/Index"
          ),
      },
      {
        path: "needs-particulars",
        name: "NeedsParticulars",
        meta: {
          title: "技术需求",
          keepAlive: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "NeedsParticulars" */ "@/views/TechnicalNeeds/Particulars.vue"
          ),
      },
      {
        path: "achievement",
        name: "Achievement",
        meta: {
          title: "科技成果",
          icon: "",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "Achievement" */ "@/views/Achievement/Index"
          ),
      },
      {
        path: "achievement-details",
        name: "AchievementDetails",
        meta: {
          title: "科技成果",
          keepAlive: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "AchievementDetails" */ "@/views/Achievement/AchievementDetails.vue"
          ),
      },
      {
        path: "expert-data",
        name: "ExpertData",
        meta: {
          title: "专家库",
          icon: "",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "ExpertData" */ "@/views/ExpertData/Index"
          ),
      },
      {
        path: "expert-details",
        name: "ExpertDetails",
        meta: {
          title: "专家库",
          icon: "",
        },
        component: () =>
          import(
            /* webpackChunkName: "ExpertData" */ "@/views/ExpertData/ExpertDetails"
          ),
      },
      {
        path: "technical-manager",
        name: "TechnicalManager",
        meta: {
          title: "技术经理人",
          icon: "",
        },
        component: () =>
          import(
            /* webpackChunkName: "TechnicalManager" */ "@/views/TechnicalManager/Index"
          ),
      },
      {
        path: "industrial-resources",
        name: "IndustrialResources",
        meta: {
          title: "产业资源库",
          icon: "",
        },
        component: () =>
          import(
            /* webpackChunkName: "IndustrialResources" */ "@/views/IndustrialResources/Index"
          ),
      },
      {
        path: "person-info",
        name: "PersonInfo",
        meta: {
          title: "我的工作台",
          keepAlive: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "PersonInfo" */ "@/views/PersonInfo/Index"
          ),
      },
    ],
  },
];

import { get, post } from "../services/http";

// 用户登录 /api/user/login
export function login(data = {}) {
  return post(`/api/user/login`, data);
}

// 退出登录 /api/duser/logout
export function logout(params = {}) {
  return post(`/api/user/logout`, params);
}

// 用户列表 /api/duser/index
export function user_index(params = {}) {
  return get(`/api/duser/index`, params);
}

// 用户列表 /api/duser/getlist
export function user_getlist(params = {}) {
  return get(`/api/duser/getlist`, params);
}

// 获取用户详情 /api/duser/getrow
export function user_getrow(params = {}) {
  return get(`/api/duser/getrow`, params);
}

// 添加用户 /api/duser/add
export function user_add(params = {}) {
  return get(`/api/duser/add`, params);
}

// 编辑用户 /api/duser/edit
export function user_edit(params = {}) {
  return get(`/api/duser/edit`, params);
}

// 删除用户 /api/duser/del
export function user_del(params = {}) {
  return get(`/api/duser/del`, params);
}

// 重置密码 /api/duser/resetpwd
export function user_resetpwd(params = {}) {
  return get(`/api/duser/resetpwd`, params);
}

// 添加人员科技成果-行业领域  /api/dropdown/insudtrylist
export function insudtrylist(params = {}) {
  return post(`/api/dropdown/insudtrylist`, params);
}

// 指南接口 /api/Flow/guide
export function guide(params = {}) {
  return post(`/api/Flow/guide`, params);
}

// 用户指南审核接口 /api/Flow/applyFor
export function applyFor(params = {}) {
  return post(`/api/Flow/applyFor`, params);
}

//官网底部-公司名/二维码
export function getDownDetail(params = {}) {
  return post(`/api/website/getDownDetail`, params);
}

//根据token获取用户信息
export function is_login(params = {}) {
  return post(`/api/user/is_login`, params);
}

//获取用户信息 /api/user/provideinfo
export function provideinfo(params = {}) {
  return get(`/api/user/provideinfo`, params);
}


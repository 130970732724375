<template>
  <div
    v-animate="{
      enterClass: 'animate__fadeIn',
      leaveClass: 'animate__fadeOut',
      leaveDelay: '200ms',
    }"
    class="login-box"
  >
    <div class="login-content">
      <div class="login-left">
        <img src="@/assets/image/loginLeft.png" alt="" />
        <div>“技术转移+知识产权”的先导者</div>
      </div>
      <div
        v-animate="{
          enterClass: 'animate__bounceInRight',
          leaveClass: 'animate__bounceOutRight',
          enterDelay: '100ms',
          leaveDelay: '100ms',
        }"
        class="login-right"
      >
        <el-form
          ref="form"
          :model="form"
          :rules="ruleForm"
          status-icon
          class="login-form"
          @submit.native.prevent
        >
          <div class="login-form-title">
            <img src="@/assets/image/logo@2x.png" alt="" />
            <span>远诺技术转移平台</span>
          </div>
          <el-form-item label="" prop="account">
            <div class="inputName">用户名</div>
            <el-input
              type="text"
              clearable
              v-model="form.account"
              placeholder="请输入账号"
              class="input1"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <div class="inputName">密码</div>
            <el-input
              :type="type"
              clearable
              v-model="form.password"
              auto-complete="off"
              placeholder="请输入密码"
              class="input1"
            >
              <i
                v-if="type === 'password'"
                slot="suffix"
                @click="changePasswordType"
                class="el-input__icon el-icon-lock"
              ></i>
              <i
                v-else
                slot="suffix"
                @click="changePasswordType"
                class="el-input__icon el-icon-unlock"
              >
                <!-- <img src="@/assets/close_eyes.png" alt="" /> -->
              </i>
              ></el-input
            >
          </el-form-item>
          <el-form-item>
            <div class="remember-container">
              <el-checkbox
                class="remember"
                v-model="remember"
                @change="rememberChange"
                >记住密码</el-checkbox
              >
              <div class="backend-btn">忘记密码?</div>
              <!-- <el-popover
                placement="right"
                width="220"
                popper-class="workface-popper"
              >
                <p v-if="loginInfo">{{ loginInfo.pwd_hint }}</p>
                <p>
                  <span>管理员：</span>
                  <span class="remember-phone">12333333333</span>
                </p>
                <div slot="reference" class="backend-btn">忘记密码?</div>
              </el-popover> -->
              <!-- <a class="backend-btn" href="javascript:;">后台管理</a> -->
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              v-waves="{ className: 'waves-light' }"
              class="login-btn"
              type="primary"
              @click="submit"
              :loading="logining"
              >登录</el-button
            >
          </el-form-item>
          <el-form-item>
            <div class="registered">没有账号？<span>立即注册</span></div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { login } from "@/api/user.js";
export default {
  components: {},
  data() {
    return {
      form: {
        account: "",
        password: "",
      },
      ruleForm: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      remember: false,
      logining: false,
      loginInfo: null,
      type: "password",
    };
  },
  created() {
    document.addEventListener("keydown", this.onKeydown);
    this.loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
    if (process.env.NODE_ENV === "development") {
      // this.form.account = "admin";
      // this.form.password = "";
    }
    this.init();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeydown);
  },
  methods: {
    init() {
      this.remember =
        localStorage.getItem("isRemember") === "true" ? true : false;

      if (this.remember && localStorage.getItem("rememberForm")) {
        this.form = JSON.parse(localStorage.getItem("rememberForm"));
      }
    },
    onKeydown(event) {
      if (event.keyCode == 13) {
        this.submit();
      }
    },
    rememberChange(val) {
      localStorage.setItem("isRemember", val);
    },
    submit(event) {
      login({
        account: this.form.account,
        password: this.form.password,
      })
        .then((res) => {
          // const navs = this.$store.state.navLevel1;
          // const jumpNav = navs.find((item) =>
          //   res.data.userInfo.auth.includes(item.auth)
          // );

          // if (!jumpNav) {
          //   return this.$message({
          //     message: "您无权限访问，请联系管理员",
          //     type: "error",
          //   });
          // }
          const userInfo = res.data.userinfo;
          const userAuth = res.data.website_auth;
          const db_auth = res.data.db_auth;

          this.$store.commit("setUserInfo", userInfo);
          this.$store.commit("setUserAuth", userAuth);
          this.$store.commit("setUserDBAuth", db_auth);
          //  sessionStorage.setItem("userInfo",JSON.stringify(userInfo))
          //  sessionStorage.setItem("userAuth",JSON.stringify(userAuth))
          //  sessionStorage.setItem("userDBAuth",JSON.stringify(db_auth))

          Cookies.set("token", userInfo.token);

          this.remember
            ? localStorage.setItem("rememberForm", JSON.stringify(this.form))
            : localStorage.removeItem("rememberForm");

          const _this = this;
          this.$message({
            message: "登录成功",
            type: "success",
            duration: 1000,
            onClose() {
              _this.$router.push("/main-layout/home");
            },
          });
        })
        .catch((err) => {
          this.logining = false;
        });
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
      //     this.logining = true;
      //     this.$router.push("/main-layout/home");
      // login(this.form)
      //   .then((res) => {
      //     res.data.userInfo.role = 1; // 1为管理员
      //     res.data.userInfo.auth = ["1", "2", "3", "4"];

      //     const navs = this.$store.state.navLevel1;
      //     const jumpNav = navs.find((item) =>
      //       res.data.userInfo.auth.includes(item.auth)
      //     );

      //     if (!jumpNav) {
      //       return this.$message({
      //         message: "您无权限访问，请联系管理员",
      //         type: "error",
      //       });
      //     }

      //     this.$store.commit("setUserInfo", res.data.userInfo);
      //     this.$store.commit("setRules", res.data.rules);

      //     this.remember
      //       ? localStorage.setItem(
      //           "rememberForm",
      //           JSON.stringify(this.form)
      //         )
      //       : localStorage.removeItem("rememberForm");

      //     const _this = this;
      //     this.$message({
      //       message: "登录成功",
      //       type: "success",
      //       duration: 1000,
      //       onClose() {
      //         _this.$router.push(jumpNav.path);
      //       },
      //     });
      //   })
      //   .catch((err) => {
      //     this.$alert("用户名或密码错误!", "提示", {
      //       confirmButtonText: "确定",
      //     });
      //   })
      //   .finally(() => {
      //     this.logining = false;
      //   });
      //   } else {
      //     console.log("error submit!");
      //     return false;
      //   }
      // });
    },
    changePasswordType() {
      this.type = this.type === "password" ? "text" : "password";
    },
  },
};
</script>

<style lang="scss">
.login-box {
  height: 100vh;
  @include flex(row, center, center);
  background: url("@/assets/image/login-background.png") no-repeat;
  background-size: 100% 100%;

  .login-content {
    display: flex;
    padding: var(--clkd-padding-default);
    border: var(--clkd-border-default);
    box-sizing: border-box;
    width: 12.6rem;
    height: 7.2rem;
    border-radius: 0.12rem;
    opacity: 1;
    background: #fff;

    .login-left {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 4.39rem;
        height: 4.47rem;
      }
      div {
        color: #9e9e9e;
        font-family: OPPOSans;
        font-size: 0.2rem;
        font-weight: 300;
        margin-top: 0.2rem;
      }
    }

    .login-right {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .login-form {
        width: 3.93rem;
        height: 4.6rem;
        // background-color: red;
        .inputName {
          color: #000;
          font-family: HarmonyOS_Sans_SC;
          font-size: 0.2rem;
          font-weight: normal;
          line-height: 0.28rem;
        }
        .login-form-title {
          height: 0.67rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-family: HarmonyOS_Sans_SC_Bold;
          font-size: 0.3rem;
          font-weight: bold;
          img {
            width: 0.48rem;
            height: 0.52rem;
          }
          span {
            margin-left: 0.13rem;
          }
        }
        .remember-container {
          display: flex;
          justify-content: space-between;
          .backend-btn {
            color: var(--clkd-color-global);
            font-family: OpenSans-Regular;
            font-size: 0.14rem;
            font-weight: normal;
            cursor: pointer;
            -webkit-user-select: none;
          }
        }
        .login-btn {
          width: 3.93rem;
          height: 0.56rem;
          background-color: var(--clkd-color-global);
          border: 0;
          font-family: PingFang SC;
          font-size: 0.18rem;
          font-weight: normal;
        }
        .registered {
          color: #c0c4cc;
          -webkit-user-select: none;
          width: 100%;
          text-align: center;
          font-family: OpenSans-Regular;
          font-size: 0.14rem;
          font-weight: normal;
          span {
            color: var(--clkd-color-global);
            cursor: pointer;
          }
        }
        .input1 {
          width: 3.92rem;
          height: 0.56rem;
          margin-top: 0.07rem;
          .el-input__suffix {
            height: 0;
          }
        }
      }
    }
  }
}
</style>

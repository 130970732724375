<template>
  <div class="custom-footer">
    <div class="footer-box custom-container">
      <div class="footer-top">
        <div class="top1 top-item">
          <div class="logo">
            <img src="@/assets/image/logo@2x.png" alt="logo" />远诺技术转移平台
          </div>
          <!-- 隐藏公司简介 -->
          <!-- <div class="top1-text">{{ downDetail.detail }}</div> -->
        </div>
        <div class="top2 top-item">
          <div class="top-title">关于我们</div>
          <div style="margin-top: 0.2rem">
            <div style="margin-bottom: 0.06rem">
              <el-popover
                placement="top-start"
                title="博客"
                width="200"
                trigger="hover"
              >
                <img
                  width="200"
                  height="200"
                  :src="$baseUrl + downDetail.blog"
                  alt="二维码"
                />
                <span slot="reference">博客</span>
              </el-popover>
            </div>
            <div style="margin-bottom: 0.06rem">
              <el-popover
                placement="top-start"
                title="官方微博"
                width="200"
                trigger="hover"
              >
                <img
                  width="200"
                  height="200"
                  :src="$baseUrl + downDetail.blog"
                  alt="二维码"
                />
                <span slot="reference">官方微博</span>
              </el-popover>
            </div>
            <div style="margin-bottom: 0.06rem">
              <el-popover
                placement="top-start"
                title="微信公众号"
                width="200"
                trigger="hover"
              >
                <img
                  width="200"
                  height="200"
                  :src="$baseUrl + downDetail.blog"
                  alt="二维码"
                />
                <span slot="reference">微信公众号</span>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="top3 top-item">
          <div class="top-title">网站导航</div>
          <ul>
            <li>
              <router-link to="/main-layout/technical-needs"
                >技术需求</router-link
              >
            </li>
            <li>
              <router-link to="/main-layout/achievement">科技成果</router-link>
            </li>
            <li>
              <router-link to="/main-layout/expert-data">专家库</router-link>
            </li>
            <li>
              <router-link to="/main-layout/technical-manager"
                >技术经理人</router-link
              >
            </li>
            <li>
              <router-link to="/main-layout/industrial-resources"
                >产业资源库</router-link
              >
            </li>
            <li>
              <router-link to="/main-layout/person-info"
                >我的工作台</router-link
              >
            </li>
          </ul>
        </div>
        <div class="top4 top-item">
          <div class="top-title">联系我们</div>
          <ul>
            <li>{{ downDetail.email }}</li>
            <li>{{ downDetail.phone }}</li>
            <!-- //a标签 -->
            <li>
              <a :href="downDetail.justek" target="_blank">{{
                downDetail.justek_name
              }}</a>
            </li>
            <li>
              <a :href="downDetail.yuannuo" target="_blank">{{
                downDetail.yuannuo_name
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-center"></div>
      <div class="footer-bottom">
        Copyright @ Yuan nuo 2023. All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import { getDownDetail } from "@/api/user";
export default {
  data() {
    return {
      downDetail: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getDownDetail().then((res) => {
        this.downDetail = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
.custom-footer {
  width: 100%;
  // margin-top: 0.5rem;
  color: black;
  background-color: #fff;
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  box-sizing: border-box;

  .el-popover__reference {
    cursor: pointer;
    font-size: 0.16rem;
    color: #181433;
    // margin: 0 0 0.1rem 0;
  }

  .footer-box {
    .footer-top {
      display: flex;
      justify-content: space-between;

      .top-item {
        width: 25%;
        padding-left: 0.4rem;
        box-sizing: border-box;

        ul {
          margin: 0;
          padding: 0;
          margin-top: 0.2rem;
          li {
            cursor: pointer;
            -webkit-user-select: none;
            @include font4rem(#181433, 16, 400);
            margin-bottom: 0.1rem;
            a {
              text-decoration: none;
              color: #181433;
            }
          }
        }
      }

      .top1 {
        .logo {
          @include flex(row, normal, center);
          @include font4rem(#000000, 20, 500);
          img {
            width: 0.24rem;
            margin-right: 0.1rem;
          }
        }
        .top1-text {
          text-indent: 2em;
          white-space: pre-wrap;
          @include font4rem(#757095, 16, 400);
          width: 70%;
          margin-top: 0.2rem;
        }
      }

      .top-title {
        @include font4rem(#000000, 20, 500);
      }
    }
  }
  .footer-center {
    height: 0.02rem;
    width: 100%;
    background-color: #bbb;
    margin: 0.2rem 0;
  }
  .footer-bottom {
    text-align: center;
    @include font4rem(#181433, 16, 400);
  }
}
</style>

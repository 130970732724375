<template>
  <el-tree
    class="global-tree"
    ref="treeBox"
    :data="treeData"
    :props="defaultProps"
    @node-click="handleNodeClick"
    node-key="uni"
    :highlight-current="true"
    :default-expand-all="true"
    :expand-on-click-node="false"
  ></el-tree>
</template>

<script>
export default {
  props: {
    treeData: Array,
    nodeClickRouterChange: {
      // 点击节点是否跳转页面，默认跳转
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultProps: {
        children: "sub",
        label: "name",
      },
    };
  },
  watch: {
    treeData(newVal) {
      this.init();
    },
  },
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        // treeBox 元素的ref   value 绑定的node-key
        this.$refs.treeBox.setCurrentKey(this.treeData[0].uni);
        this.$emit("checkNode", this.treeData[0]);
        // this.$refs.treeBox.setCurrentNode(this.treeData[0].id);
      });
    },
    handleNodeClick(data, e) {
      console.log(
        "debug ~ file: GlobalTree.vue ~ line 50 ~ handleNodeClick ~ e",
        e
      );
      if (data.path && this.nodeClickRouterChange) {
        this.$router.push(data.path);
      } else {
        if (data.type == 4) {
          const index = e.parent.data.sub.findIndex(
            (item) => item.id === data.id
          );
          this.$emit("checkNode", data, index);
        } else {
          this.$emit("checkNode", data);
        }
      }
    },
  },
};
</script>

<style lang="scss">
// .global-tree {
//   width: 100%;
//   height: 100%;
//   background: transparent;
//   color: #fff;
//   padding: 0.4rem 0.2rem;
//   box-sizing: border-box;
//   // .el-tree-node:focus > .el-tree-node__content {
//   //   background-color: transparent;
//   //   color: #00ffff;
//   // }
//   .el-tree-node__content:hover {
//     background-color: transparent;
//     color: #00ffff;
//   }
// }
// .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
//   background-color: transparent;
//   color: #00ffff;
// }
</style>
// import Vue from 'vue'
import "./utils/rem";
// import ElementUI from "element-ui";
// import 'element-ui/lib/theme-chalk/index.css';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import "./components/global/index";
import "vue2-animate/src/sass/vue2-animate.scss";
import "animate.css";
import "./styles/index.scss";
import console from "@/utils/dateLog";
import utils from "@/utils/index";
import fontSize from "./utils/fontSize";
// import setRootFontSize from "./utils/rem";
import "./directive/index";
import i18n from "@/i18n";
import Cookies from "js-cookie";
import { provideinfo } from "@/api/user";

Object.assign(window.console, console);

// 设置默认主题
store.commit("setTheme", "theme-default");

document.body.classList.add(store.state.themeClass);

moment.locale("zh-cn");
// Vue.use(ElementUI);
Vue.prototype.$Moment = moment;
Vue.prototype.$FontSize = fontSize;
Vue.prototype.$utils = utils;
Vue.prototype.$baseUrl =
  process.env.NODE_ENV === "development" ? "proxy" : window.g.baseUrl;

Vue.config.productionTip = false;

// new Vue({
//   router,
//   store,
//   i18n,
//   render: (h) => h(App),
// }).$mount("#app");


(async () => {
  try {
    const token = Cookies.get("token")
    console.log("🚀 ~ file: main.js:50 ~ token:", token)

    if (token) {
      console.log("🚀 ~ file: main.js:53 ~ token:", token, "111111");
      const res = await provideinfo();
      console.log("🚀 ~ file: main.js:55 ~ res:", res, "22222222")

      if (res.data) {
        const userInfo = res.data.userinfo;
        const userDBAuth = res.data.db_auth;
        const userAuth = res.data.website_auth;
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
        sessionStorage.setItem("userDBAuth", JSON.stringify(userDBAuth));
        sessionStorage.setItem("userAuth", JSON.stringify(userAuth));
      }
    } else {
      console.log("没token", "333333333");
      sessionStorage.setItem("userInfo", null);
      sessionStorage.setItem("userDBAuth", []);
      sessionStorage.setItem("userAuth", []);
    }
  } catch (error) {
    console.log("🚀 ~ file: main.js:65 ~ error:", error, "报错")
  } finally {
    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  }

})();
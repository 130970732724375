import Vue from 'vue'
import theme from './theme'
import animate from './animate'
import waves from './waves'
import btnPermission from './btnPermission'

Vue.directive('theme', theme)
Vue.directive('animate', animate)
Vue.directive('waves', waves)
Vue.directive('btnPermission', btnPermission)

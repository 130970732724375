export default function () {
  if (window._axiosQueue === undefined) {
    window._axiosQueue = []
  }
  // 循环缓存的请求 取消标识数组 取消所有关联的请求
  window._axiosQueue.forEach(request => {
    // 失败函数中返回自定义错误信息
    request.cancel('请求取消')
  })
  window._axiosQueue.length = 0
}

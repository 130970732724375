<template>
  <div class="main-layout-box">
    <div class="main-layout-nav">
      <Menu />
    </div>
    <div class="main-layout-content">
      <transition
        enter-active-class="fadeInLeft"
        leave-active-class="fadeOutLeft"
        mode="out-in"
      >
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
      </transition>
      <transition
        enter-active-class="fadeInLeft"
        leave-active-class="fadeOutLeft"
        mode="out-in"
      >
        <router-view v-if="!$route.meta.keepAlive" />
      </transition>
    </div>
    <CustomFooter />
  </div>
</template>

<script>
import Menu from "./components/Menu.vue";
import CustomFooter from "@/components/custom/CustomFooter";

export default {
  data() {
    return {};
  },
  components: {
    Menu,
    CustomFooter,
  },
  mounted() {},
};
</script>

<style lang="scss">
.main-layout-box {
  .main-layout-nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
  }
}
// @media screen and (max-width: 1440px) {
//   .main-layout-box {
//     min-width: 14.4rem;
//   }
// }
// .main-layout-content {
//   height: calc(100vh - 0.76rem);
//   overflow-y: auto;
//   background: var(--clkd-default-page-backgroud);
// }
</style>

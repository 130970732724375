<template>
  <div :class="['menu-box', isDefault && 'default']">
    <div class="custom-container">
      <div class="mask"></div>
      <div class="logo">
        <img v-if="isDefault" src="@/assets/image/logo@2x.png" alt="" />
        <img v-else src="@/assets/image/logo@2x_2.png" alt="" />
        <div>远诺技术转移平台</div>
      </div>
      <div class="nav">
        <el-menu
          :default-active="active"
          class="el-menu-demo uistyle"
          mode="horizontal"
          @select="handleOpen"
        >
          <el-menu-item index="/main-layout/home">首页</el-menu-item>
          <el-menu-item index="/main-layout/technical-needs"
            >技术需求</el-menu-item
          >
          <el-menu-item index="/main-layout/achievement">科技成果</el-menu-item>
          <el-menu-item index="/main-layout/expert-data">专家库</el-menu-item>
          <el-menu-item index="/main-layout/technical-manager"
            >技术经理人</el-menu-item
          >
          <el-menu-item index="/main-layout/industrial-resources"
            >产业资源库</el-menu-item
          >
          <el-menu-item
            v-if="userInfo && hasAtuh"
            index="/main-layout/person-info"
            >我的工作台</el-menu-item
          >
        </el-menu>
      </div>
      <div class="user">
        <transition
          enter-active-class="fadeInRight"
          leave-active-class="fadeOutRight"
          mode="out-in"
        >
          <el-button class="btn" @click="login" v-if="!userInfo"
            >登录/注册</el-button
          >
          <el-dropdown @command="handleCommand" v-else>
            <span class="el-dropdown-link">
              <div class="info">
                <img :src="$baseUrl + userInfo.avatar" alt="" />
                <!-- <img src="@/assets/image/Avatar.png" alt="" /> -->
                <div :class="['name', isDefault && 'name_default']">
                  {{ userInfo.nickname }}
                </div>
              </div>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>个人资料</el-dropdown-item> -->
              <el-dropdown-item command="quit"
                ><span>退出</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cookies from "js-cookie";
import { logout } from "@/api/user";
export default {
  data() {
    return {
      isDefault: true,
      isCollapse: false,
      active: "",
      userInfo: null,
    };
  },
  computed: {
    ...mapState({
      // userInfo: (state) => state.userInfo,
      // 判断菜单访问权限
      hasAtuh: (state) =>
        state.userAuth.some((item) => ["我的工作台"].includes(item.title)),
    }),
  },
  watch: {
    // $route(to, from) {
    //   this.active = to.path;
    // },
    $route: {
      handler(to, from) {
        window.removeEventListener("scroll", this.handleScroll);
        if (["/main-layout/needs-particulars"].includes(to.path)) {
          this.active = "/main-layout/technical-needs";
        } else if (["/main-layout/achievement-details"].includes(to.path)) {
          this.active = "/main-layout/achievement";
        } else if (["/main-layout/expert-details"].includes(to.path)) {
          this.active = "/main-layout/expert-data";
        } else {
          this.active = to.path;
        }

        if (to.name == "Home") {
          this.isDefault = false;
          // 监听页面滚动
          window.addEventListener("scroll", this.handleScroll);
        } else {
          this.isDefault = true;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.init();
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    init() {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    },
    handleScroll(e) {
      const maskEl = document.querySelector(".menu-box .mask");
      const htmlFontSize = parseInt(
        window.getComputedStyle(document.documentElement).fontSize
      );
      const clientHeight =
        document.documentElement.clientHeight || window.innerHeight;
      const changeHeight = 0.9 * clientHeight; // 90%屏幕高度为轮播图高度
      const scrollTop = document.documentElement.scrollTop;
      // 数据概率chart
      const overviewChartBoxEl = document.getElementById("overview-chart-box");
      // 需求列表
      const demandBoxEl = document.getElementById("demand-box");
      // 成果列表
      const resultBoxEl = document.getElementById("result-box");
      // 成功案例
      const successCaseBoxEl = document.getElementById("success-case-box");
      // 专家照片
      const expertBoxEl = document.getElementById("expert-box");
      // 经理人照片
      const technicalManagerBoxEl = document.getElementById(
        "technical-manager-box"
      );
      const navsEl = document.getElementById("navs-box");

      if (clientHeight >= overviewChartBoxEl.getBoundingClientRect().top) {
        overviewChartBoxEl.classList.add(
          "animate__animated",
          "animate__fadeInLeft",
          "animate__speed-1s",
          "animate__delay-100ms"
        );
      }
      if (clientHeight >= demandBoxEl.getBoundingClientRect().top) {
        $(demandBoxEl)
          .children()
          .each((index, item) => {
            $(item).addClass(
              `animate__animated animate__fadeInRight animate__speed-1s animate__delay-${
                index + 1
              }00ms`
            );
          });
      }
      if (clientHeight >= resultBoxEl.getBoundingClientRect().top) {
        $(resultBoxEl)
          .children()
          .each((index, item) => {
            $(item).addClass(
              `animate__animated animate__fadeInLeft animate__speed-1s animate__delay-${
                index + 1
              }00ms`
            );
          });
      }
      if (clientHeight >= successCaseBoxEl.getBoundingClientRect().top) {
        successCaseBoxEl.classList.add(
          "animate__animated",
          "animate__fadeInTopLeft",
          "animate__speed-1s",
          "animate__delay-300ms"
        );
      }
      if (clientHeight >= expertBoxEl.getBoundingClientRect().top) {
        $(expertBoxEl)
          .children()
          .each((index, item) => {
            $(item).addClass(
              `animate__animated animate__bounceIn animate__speed-1s animate__delay-${
                index + 1
              }00ms`
            );
          });
      }
      if (clientHeight >= technicalManagerBoxEl.getBoundingClientRect().top) {
        $(technicalManagerBoxEl)
          .children()
          .each((index, item) => {
            $(item).addClass(
              `animate__animated animate__zoomIn animate__speed-1s animate__delay-${
                index + 1
              }00ms`
            );
          });
      }

      if (scrollTop > changeHeight) {
        this.isDefault = true;
        maskEl.style.display = "none";
        navsEl.classList.add("fixed");
      } else {
        const ratio = 1 - (changeHeight - scrollTop) / changeHeight;
        maskEl.style.display = "block";
        maskEl.style.backdropFilter = `blur(${ratio * 10}px)`;
        // maskEl.style.backgroundImage = `linear-gradient(180deg, rgba(0, 0, 0, ${ratio}) 0%, rgba(0, 0, 0, 0) 100%)`;
        // navsEl.style.position = "absolute";
        navsEl.classList.remove("fixed");
        this.isDefault = false;
      }

      if (navsEl.getBoundingClientRect().top <= htmlFontSize * 1.1) {
        navsEl.classList.add("fixed");
      }
      // console.log(
      //   "🚀 ~ file: Menu.vue:216 ~ handleScroll ~ navsEl.getBoundingClientRect().top:",
      //   navsEl.getBoundingClientRect().top,
      //   "---",
      //   scrollTop
      // );

      // console.log(
      //   "🚀 ~ file: Menu.vue:98 ~ handleScroll ~ e:",
      //   e,
      //   this.isDefault,
      //   htmlFontSize,
      //   document.documentElement.scrollTop
      // );
    },
    handleOpen(key, keyPath) {
      this.$router.push(keyPath[0]);
    },
    login() {
      this.$router.push("/login");
    },
    handleCommand(command) {
      switch (command) {
        case "quit":
          this.loginOut();
          break;

        default:
          break;
      }
    },
    loginOut() {
      logout().then((res) => {
        this.$message({
          // showClose: true,  //显示消息关闭
          message: "退出成功",
          type: "success",
          duration: 1500,
          customClass: "right-box"
        });
        this.$store.commit("setUserInfo", null);
        this.$store.commit("setUserAuth", []);
        this.$store.commit("setUserDBAuth", []);
        this.init();
        Cookies.remove("token");
        if (this.$route.path !== "/main-layout/home") {
          this.$router.push("/main-layout/home");
        }
      });
    },
  },
};
</script>

<style lang="scss">
.menu-box {
  height: 0.8rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  box-shadow: 0 0.02rem 0.06rem 0 rgba(0, 0, 0, 0.05);

  .custom-container {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .mask {
    display: none;
    width: 100%;
    height: 0.8rem;
    position: absolute;
    left: 0;
    top: 0;
    // background: linear-gradient(
    //   180deg,
    //   rgba(0, 0, 0, 0) 0%,
    //   rgba(0, 0, 0, 0) 100%
    // );
    pointer-events: none;
    backdrop-filter: blur(0);
  }
  transition: all 0.3s ease-in-out;

  .logo {
    width: 3rem;
    display: flex;
    align-items: center;
    z-index: 19;
    img {
      width: 0.48rem;
      height: 0.52rem;
    }
    div {
      margin-left: 0.1rem;
      font-size: 0.24rem;
      font-weight: normal;
      color: #fff;
      white-space: nowrap;
    }
  }
  .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    background: transparent;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
  }
  .el-menu {
    background: transparent;

    .el-menu-item {
      color: #fff;

      padding: 0 0.2rem;

      &.is-active {
        // color: rgba(255, 255, 255, 1);
        color: var(--clkd-color-global) !important;
        font-weight: 700;
      }
      &:hover {
        color: var(--clkd-color-global) !important;
      }
    }
  }
  .nav {
    flex: 1;
    height: 100%;
    .uistyle {
      height: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 0;
      li {
        line-height: 0.8rem;
        color: rgba(255, 255, 255, 0.6);
        border-bottom: 0;
        font-size: 0.18rem;
        font-weight: 400;
      }
    }
  }
  .user {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 2rem;
    height: 100%;
    z-index: 20;
    .btn {
      width: 1.37rem;
      height: 0.52rem;
      border-radius: 0.06rem;
      opacity: 1;
      color: #fff;
      font-size: 0.14rem;
      font-weight: 400;
      background-color: var(--clkd-color-global);
      border-color: var(--clkd-color-global);
    }
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #000;
    display: flex;
    align-items: center;
    .info {
      display: flex;
      align-items: center;
      img {
        width: 0.32rem;
        height: 0.32rem;
        border-radius: 50%;
      }
      .name {
        margin-left: 0.1rem;
        font-size: 0.18rem;
        color: rgba(255, 255, 255, 0.6);
      }

      .name_default {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &.default {
    background-color: #fff;
    transition: all 0.3s ease-in-out;

    .logo {
      div {
        color: #000;
      }
    }

    .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
      background: transparent;
      color: rgba(0, 0, 0, 1);
      font-weight: 700;
    }
    .el-menu {
      background: transparent;

      .el-menu-item {
        color: #fff;

        &.is-active {
          color: rgba(0, 0, 0, 1);
          font-weight: 700;
        }
      }
    }

    .nav {
      .uistyle {
        li {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}
</style>

<template>
  <div class="advanced-search custom-box-shadow" :style="warpStyle">
    <el-button v-if="!isAdvancedSearchShow" @click="open" type="primary"
      >高级检索</el-button
    >
    <div class="advanced-search-content" v-if="isAdvancedSearchShow">
      <el-form ref="ruleForm" label-width="0.8rem" class="demo-ruleForm">
        <el-row>
          <template v-for="(item, index) in searchList">
            <el-col :span="12" :key="index">
              <el-form-item label="行业分类">
                <el-select
                  v-model="item.searchValue"
                  placeholder="请选择"
                  @change="(val) => changeSelect(val, item, index)"
                  @visible-change="(val) => visibleChange(val, item)"
                >
                  <el-option
                    v-for="el in options"
                    :key="el.value"
                    :label="el.label"
                    :value="el.value"
                    :disabled="el.disabled"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-model="item.inputValue"
                  placeholder="请输入"
                ></el-input>
                <img
                  class="del-icon"
                  v-if="index != 0"
                  src="@/assets/icon_del.png"
                  alt=""
                  @click="delSearch(index)"
                />
              </el-form-item>
            </el-col>
          </template>
        </el-row>

        <el-form-item>
          <el-button class="addSearchBtn" icon="el-icon-plus" @click="add"
            >添加筛选条件</el-button
          >
        </el-form-item>
      </el-form>
      <div class="search-form-btn">
        <el-button @click="resetForm('ruleForm')">取消</el-button>

        <el-button type="primary" @click="submitForm('ruleForm')"
          >检索</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getIndustry } from "@/api/air";
export default {
  props: {
    searchParams: {
      type: Object,
      default() {
        return {};
      },
    },
    isAdvancedSearchShow: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      options: [],
      checked: [],
      input: "",
      value: "",
      searchList: [
        {
          searchValue: "",
          inputValue: "",
        },
      ],
      warpStyle: {
        width: "1.2rem",
        background: "transparent",
        padding: "0",
        marginLeft: "0.2rem",
      },
    };
  },
  watch: {
    isAdvancedSearchShow: {
      handler() {
        this.changeView();
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getSearchType();
      this.changeView();
    },
    changeView() {
      if (this.isAdvancedSearchShow) {
        this.warpStyle.width = "100%";
        this.warpStyle.background = "#fff";
        this.warpStyle.padding = "0.2rem";
        this.warpStyle.marginLeft = "0";
      } else {
        this.warpStyle.width = "1.2rem";
        this.warpStyle.background = "transparent";
        this.warpStyle.padding = "0";
        this.warpStyle.marginLeft = "0.2rem";
      }
    },
    getSearchType() {
      // getDemandSearchOptions().then((res) => {
      //   let arr = res.data;
      //   arr.forEach((val) => {
      //     val.value = val.id;
      //     val.label = val.name;
      //     val.disabled = false;
      //   });
      //   this.options = [...this.options, ...arr];
      // });
      getIndustry().then((res) => {
        let arr = res.data.lists;
        arr.forEach((val) => {
          val.value = val.id;
          val.label = val.name;
          val.disabled = false;
        });
        this.options = [...this.options, ...arr];
      });
    },
    open() {
      this.$emit("openAdvancedSearch");
    },
    submitForm() {
      const searchIds = Array.from(
        this.searchList,
        ({ searchValue }) => searchValue
      );
      const inputValues = Array.from(
        this.searchList,
        ({ inputValue }) => inputValue
      );
      this.searchParams.search_type = searchIds.join(",");
      this.searchParams.keyword = inputValues.join(",");
      this.searchParams.type = 2;
      this.$emit("submitForm", this.searchParams);
      // this.$emit("closeAdvancedSearch");
    },
    resetForm() {
      this.searchList = [
        {
          searchValue: "",
          inputValue: "",
        },
      ];
      this.$emit("submitForm");
      this.$emit("closeAdvancedSearch");
    },
    changeSelect(val, data, index) {
      let flag = false;
      let arr = [...this.checked];
      if (!this.checked[index]) {
        data.index = index;
        arr.push(data);
        this.checked = [...arr];
      } else {
        this.checked[index].searchValue = val;
      }
    },
    visibleChange(val, data) {
      const checked = Array.from(
        this.checked,
        ({ searchValue }) => searchValue
      );
      if (val) {
        this.options.forEach((el) => {
          if (checked.includes(el.value) && el.value != data.searchValue) {
            el.disabled = true;
          } else {
            el.disabled = false;
          }
        });
      }
    },
    add() {
      this.searchList.push({
        searchValue: "",
        inputValue: "",
      });
    },
    delSearch(index) {
      this.searchList.splice(index, 1);
      this.checked.splice(index, 1);
    },
  },
};
</script>

<style></style>

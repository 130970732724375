<template>
  <div class="person-middle-search">
    <template v-for="(el, i) in typeList">
      <div class="tyep-item" :key="i">
        <span class="tag-title">{{ el.title }}: </span>
        <div class="tag-content" :style="el.showMore" ref="tagContent">
          <template v-for="item in el.data">
            <el-tag
              :class="el.checkedId == item.id ? 'el-tag-active' : ''"
              :key="item.index"
              type="dark"
              @click="clickTag(item, i)"
            >
              {{ item.name }}
            </el-tag>
          </template>
        </div>
        <el-tag
          class="more-btn"
          v-if="el.showMore.isShow && el.data.length > 12"
          type="dark"
          @click="showMoreTag(el, i)"
          >{{ el.showMore.text }}</el-tag
        >
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    typeList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      // territory: [
      //   {
      //     id: "all",
      //     name: "全部",
      //   }],
      // territoryChecked: "all",
      // technosphere: [
      //   {
      //     id: "all",
      //     name: "全部",
      //   },
      //   {
      //     id: "1",
      //     name: "征集中",
      //   },
      //   {
      //     id: "2",
      //     name: "对接中",
      //   },
      //   {
      //     id: "3",
      //     name: "已完成",
      //   },
      // ],
      // technosphereChecked: "all",
      // showMore: [
      //   { isShow: true, height: "0.5rem", text: "更多" },
      //   { isShow: true, height: "0.5rem", text: "更多" },
      // ],
    };
  },
  mounted() {
  },
  methods: {
    // DOM
    onRefs(){
      console.log();
    },
    //行业领域筛选
    clickTag(tag, index) {
      this.typeList[index].checkedId = tag.id;
      this.$emit("typeSearchChange", this.typeList[index]);
    },
    //技术领域筛选
    showMoreTag(el, index) {
      console.log(el);
      // if(el.showMore.height =="0.5rem"){
      //   console.log("000000");
      // }
      if (this.typeList[index].showMore.height == "auto") {
        this.typeList[index].showMore.height = "0.5rem";
        this.typeList[index].showMore.text = "更多";
      } else {
        this.typeList[index].showMore.height = "auto";
        this.typeList[index].showMore.text = "收起";
      }
    },
  },
};
</script>

<style>
</style>
export default {
  bind(el, binding, vnode) {
    const enterClass = [binding.value.enterClass]
    const leaveClass = [binding.value.leaveClass]

    if (binding.value.enterDelay) {
      enterClass.push('animate__delay-' + binding.value.enterDelay)
    }

    if (binding.value.leaveDelay) {
      leaveClass.push('animate__delay-' + binding.value.leaveDelay)
    }

    el.classList.add(
      'directive__animate__animated',
      'animate__animated',
      ...enterClass
    )
    el.dataset.enterClass = JSON.stringify(enterClass)
    el.dataset.leaveClass = JSON.stringify(leaveClass)
  }
}

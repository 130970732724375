<template>
  <el-tooltip v-bind="option" popper-class="global-auto-tooltip">
    <slot>
      <div :class="[ellipsisClass, customClass]" @mouseenter="onMouseEnter">
        {{ $attrs.content }}
      </div>
    </slot>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    ellipsisClass: {
      // 超出几行隐藏
      type: String,
      default: "one-ellipsis", // 可选two-ellipsis， three-ellipsis  path:src\styles\common.scss
    },
    customClass: {
      // 自定义样式，建议设定元素宽度
      type: String,
      default: "",
    },
  },
  computed: {
    option() {
      return {
        disabled: this.disabled,
        enterable: false,
        ...this.$attrs,
      };
    },
  },
  data() {
    return {
      disabled: false,
    };
  },
  methods: {
    onMouseEnter(event) {
      const ev = event.target;
      // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。

      if (
        ev.scrollWidth > ev.clientWidth ||
        ev.scrollHeight > ev.clientHeight
      ) {
        // 实际宽度 > 可视宽度  文字溢出
        this.disabled = false;
      } else {
        // 否则为不溢出
        this.disabled = true;
      }
    },
  },
};
</script>

<style></style>

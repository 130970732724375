export default function (url) {
  const fileNameArr = url.split('/')
  const fileName = fileNameArr[fileNameArr.length - 1]
  let link = document.createElement('a')

  link.style.display = 'none'
  link.href = url
  link.id = 'temp-download'
  link.setAttribute('download', fileName)

  document.body.appendChild(link)
  link.click()
  document.getElementById(link.id).remove()
}
